import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../../layouts'

import AboutSidenav from "../../partials/about-sidenav"

import '../../scss/about.scss'

import partners from "../../data/partners.yaml"

// import equinorlogo from '../../assets/images/equinorlogo.png'
// import akerbplogo from '../../assets/images/akerbplogo.png'
// import conocophillipslogo from '../../assets/images/conocophillipslogo.png'
// import wintershalllogo from '../../assets/images/wintershalllogo.png'
// import lundinlogo from '../../assets/images/lundinlogo.png'
// import totallogo from '../../assets/images/totallogo.png'
// import kongsberg from '../../assets/images/Kongsberg_logo.png'
// import odfjell from '../../assets/images/OdfjellDrilling_logo.png'
// import sekal from '../../assets/images/Sekal-logo.png'
// import varlogo from '../../assets/images/var_energi_logo_center.png'


const AboutManagementPage = ({data}) => {
  console.log(partners)

  return (
    <Layout>
      <div className="about partners">

      <Helmet>
        <title>DigiWells – Industrial partners</title>
        <meta property="og:title" content="DigiWells – Industrial partners" />
        <meta name="description" content="Get to know our industrial partners." />
        <meta property="og:description" content="Get to know our industrial partners." />
        {
          /*
          <meta name="image" content={image} />
          <meta property="og:image" content={image} />
          */
        }
      </Helmet>

        <div className="container">
          <AboutSidenav />

          <div className="about-content">
            <h1 className="page-title">Industrial sponsors</h1>
            <div className="logo-wrapper">
              {
                  partners.industrialPartners &&
                  partners.industrialPartners.map(function(partner) {
                    return (
                      // <a href={partner.url}>
                      <img className="logo" 
                      alt={partner.company + " logo"} 
                      src={partner.logo}/>
                      // </a>
                    )
                  })
              }
            </div>
            <h1 className="page-title">In-kind partners</h1>
            <div className="logo-wrapper">
              {
                  partners.industrialPartnersInKind &&
                  partners.industrialPartnersInKind.map(function(partner) {
                    return (
                      <a class="link" href={partner.url}>
                      <img className="logo" 
                      alt={partner.company + " logo"} 
                      src={partner.logo}/>
                      </a>
                    )
                  })
              }
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default AboutManagementPage
