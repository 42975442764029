import React from 'react'
import { Link } from 'gatsby'

const AboutSidenav = ({data}) => {
  return (
    <div className="sidenav">
      <Link to="/about/" activeClassName="active">About DigiWells</Link>
      <Link to="/about/consortium/" activeClassName="active">Consortium</Link>
      <Link to="/about/board/" activeClassName="active">The Board</Link>
      <Link to="/about/management/" activeClassName="active">Management</Link>
      <Link to="/about/key-researchers/" activeClassName="active">Key researchers</Link>
      {/* <Link to="/about/annual-reports/" activeClassName="active">Annual reports</Link> */}
      {/* <Link to="/about/research-partners/" activeClassName="active">Consortium</Link> */}
      {/* <Link to="/about/research-partners/" activeClassName="active">Research partners</Link> */}
      {/* <Link to="/about/industrial-partners/" activeClassName="active">Industrial partners</Link> */}
      <Link to="/about/acknowledgements/" activeClassName="active">Acknowledgements</Link>
      <Link to="/about/logos-templates/" activeClassName="active">Logos and templates</Link>
    </div>
  )
}
export default AboutSidenav
